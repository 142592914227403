/* eslint-disable  */

export default {
  "About us": "About us",
  "Account": "Account",
  "Add new address": "Add new address",
  "Add to compare": "Add to compare",
  "Add to Wishlist": "Add to Wishlist",
  "Additional Information": "Additional Information",
  "All Orders": "All Orders",
  "Allow order notifications": "Allow order notifications",
  "Apply": "Apply",
  "Applied Coupon": "Applied Coupon",
  "Attention!": "Attention!",
  "Back to home": "Back to home",
  "Back to homepage": "Vai all'Homepage",
  "Billing": "Billing",
  "Billing address": "Billing address",
  "Brand": "Brand",
  "Cancel": "Cancel",
  "Cart": "Cart",
  "Categories": "Categories",
  "Change": "Change",
  "Change password your account": "If you want to change the password to access your account, enter the following information",
  "Clear all": "Clear all",
  "Color": "Color",
  "Commercial information": "and agree to receive personalized commercial information from Brand name by email",
  "Contact details updated": "Keep your addresses and contact details updated.",
  "Contact us": "Contact us",
  "Continue to billing": "Continue to billing",
  "Continue to payment": "Continue to payment",
  "Continue to shipping": "Continue to shipping",
  "Cookies Policy": "Cookies Policy",
  "Create an account": "Create an account",
  "Customer Reviews": "Customer Reviews",
  "Customer service": "Customer service",
  "Date": "Date",
  "Default Billing Address": "Default Billing Address",
  "Default Shipping Address": "Default Shipping Address",
  "Delete": "Delete",
  "Departments": "Departments",
  "Description": "Description",
  "Details and status orders": "Check the details and status of your orders in the online store. You can also cancel your order or request a return.",
  "Discount": "Discount",
  "Done": "Done",
  "Download": "Download",
  "Download all": "Download all",
  "Edit": "Edit",
  "Email address": "Email address",
  "Empty": "Looks like you haven’t added any items to the bag yet. Start shopping to fill it in.",
  "Empty bag": "Empty bag",
  "Enjoy your free account": "Enjoy these perks with your free account!",
  "Enter promo code": "Enter promo code",
  "Feedback": "Your feedback is important to us. Let us know what we could improve.",
  "Feel free to edit": "Feel free to edit any of your details below so your account is always up to date",
  "Filters": "Filters",
  "Find out more": "Find out more",
  "First Name": "First Name",
  "Forgot Password": "If you can’t remember your password, you can reset it.",
  "Forgot Password Modal Email": "Email you are using to sign in:",
  "forgotPasswordConfirmation": "Thanks! If there is an account registered with the {0} email, you will find message with a password reset link in your inbox.",
  "Forgotten password?": "Password dimenticata?",
  "Go back": "Go back",
  "Go back shopping": "Go back shopping",
  "Go back to shop": "Go back to shop",
  "Go to checkout": "Go to checkout",
  "Go to cart": "Go to cart",
  "Guarantee": "Guarantee",
  "Help": "Help",
  "Help & FAQs": "Help & FAQs",
  "hide": "hide",
  "Home": "Home",
  "I agree to": "I agree to",
  "I confirm subscription": "I confirm subscription",
  "I want to create an account": "I want to create an account",
  "Info after order": "You can log to your account using e-mail and password defined earlier. On your account you can edit your profile data, check history of transactions, edit subscription to newsletter.",
  "Instruction1": "Take care of me",
  "Instruction2": "Just here for the care instructions?",
  "Instruction3": "Yeah, we thought so",
  "It was not possible to request a new password, please check the entered email address.": "It was not possible to request a new password, please check the entered email address.",
  "Item": "Item",
  "Items": "Items",
  "Kidswear": "Kidswear",
  "Last Name": "Last Name",
  "Let’s start now – we’ll help you": "Let’s start now – we’ll help you.",
  "Log into your account": "Log into your account",
  "Login": "Accedi",
  "login in to your account": "login in to your account",
  "Looks like you haven’t added any items to the bag yet. Start shopping to fill it in.": "Looks like you haven’t added any items to the bag yet. Start shopping to fill it in.",
  "Looks like you haven’t added any items to the Wishlist.": "Looks like you haven’t added any items to the Wishlist.",
  "Make an order": "Make an order",
  "Manage addresses": "Manage all the addresses you want (work place, home address...) This way you won\"t have to enter the address manually with each order.",
  "Manage billing addresses": "Manage all the billing addresses you want (work place, home address...) This way you won\"t have to enter the billing address manually with each order.",
  "Manage shipping addresses": "Manage all the shipping addresses you want (work place, home address...) This way you won\"t have to enter the shipping address manually with each order.",
  "Match it with": "Match it with",
  "Men fashion": "Men fashion",
  "Menu": "Menu",
  "My billing and shipping address are the same": "My billing and shipping address are the same",
  "My Cart": "My Cart",
  "No account": "Non hai un account?",
  "or": "oppure",
  "or fill the details below": "or fill the details below",
  "Order ID": "Order ID",
  "Order information": "Order information",
  "Order No.": "Order No.",
  "Order summary": "Order summary",
  "Other products you might like": "Other products you might like",
  "Out of stock": "Out of stock",
  "Password": "Password",
  "Password Changed": "Password successfuly changed. You can now go back to homepage and sign in.",
  "Pay for order": "Pay for order",
  "Payment": "Payment",
  "Payment & Delivery": "Payment & Delivery",
  "Payment Method": "Payment Method",
  "Payment Methods": "Payment Methods",
  "Personal details": "Personal details",
  "Please type your current password to change your email address.": "Please type your current password to change your email address.",
  "Price": "Price",
  "Privacy": "Privacy",
  "Privacy Policy": "Privacy Policy",
  "Product": "Product",
  "Product suggestions": "Product suggestions",
  "Products": "Products",
  "Products found": "Products found",
  "Purchase terms": "Purchase terms",
  "Quality in the details": "Quality in the details",
  "Quantity": "Quantity",
  "Read all reviews": "Read all reviews",
  "Read and understand": "I have read and understand the",
  "Read reviews": "Read reviews",
  "Register": "Register",
  "Register today": "Registrati ora",
  "Remove": "Remove",
  "Remove Address": "Remove Address",
  "Remove from Wishlist": "Remove from Wishlist",
  "Repeat Password": "Repeat Password",
  "Reset Password": "Reset Password",
  "Review my order": "Review my order",
  "Same as shipping address": "Same as shipping address",
  "Save changes": "Save changes",
  "Save for later": "Save for later",
  "Save Password": "Save Password",
  "Search": "Search",
  "Search for items": "Search for items",
  "Search results": "Search results",
  "Sections that interest you": "Sections that interest you",
  "See all results": "See all results",
  "See more": "See more",
  "Select payment method": "Select payment method",
  "Select shipping method": "Select shipping method",
  "Send my feedback": "Send my feedback",
  "Set up newsletter": "Set up your newsletter and we will send you information about new products and trends from the sections you selected every week.",
  "Share your look": "Share your look",
  "Shipping": "Shipping",
  "Shipping address": "Shipping address",
  "Shipping details": "Shipping details",
  "Shipping method": "Shipping method",
  "Show":"Show",
  "show more": "show more",
  "Show on page": "Show on page",
  "Sign in": "Sign in",
  "Size guide": "Size guide",
  "Sign Up for Newsletter": "Sign Up for Newsletter",
  "Sort by": "Sort by",
  "Sort: Default": "Default",
  "Sort: Name A-Z": "Name A-Z",
  "Sort: Name Z-A": "Name Z-A",
  "Sort: Price from high to low": "Price from high to low",
  "Sort: Price from low to high": "Price from low to high",
  "Start shopping": "Start shopping",
  "Status": "Status",
  "Subscribe": "Subscribe",
  "Subscribe to newsletter": "Subscribe to newsletter",
  "subscribeToNewsletterModalContent": "After signing up for the newsletter, you will receive special offers and messages from VSF via email. We will not sell or distribute your email to any third party at any time. Please see our {0}.",
  "Subtotal": "Subtotal",
  "Subtotal price": "Subtotal price",
  "Successful placed order": "You have successfully placed the order. You can check status of your order by using our delivery status feature. You will receive an order confirmation e-mail with details of your order and a link to track its progress.",
  "Terms and conditions": "Terms and conditions",
  "Thank you": "Thank you",
  "Thank You Inbox": "If the message is not arriving in your inbox, try another email address you might’ve used to register.",
  "Total": "Total",
  "Order Total": "Order Total",
  "Total items": "Total items",
  "Total price": "Total price",
  "Update password": "Update password",
  "Update personal data": "Update personal data",
  "Use your personal data": "At Brand name, we attach great importance to privacy issues and are committed to protecting the personal data of our users. Learn more about how we care and use your personal data in the",
  "User Account": "User Account",
  "View": "View",
  "View details": "View details",
  "Vue Storefront Next": "Vue Storefront Next",
  "Who we are": "Who we are",
  "Wishlist": "Wishlist",
  "Women fashion": "Women fashion",
  "You added {product} to your shopping cart.": "You added {product} to your shopping cart.",
  "You are not authorized, please log in": "You are not authorized, please log in",
  "You can unsubscribe at any time": "You can unsubscribe at any time",
  "You currently have no orders": "You currently have no orders",
  "You haven\’t searched for items yet": "You haven’t searched for items yet.",
  "Your bag is empty": "Your bag is empty",
  "Your current email address is": "Your current email address is",
  "Your email": "Your email",
  "The email field must be a valid email":"The email field must be a valid email",
  'You have submitted no reviews': 'You have submitted no reviews',
  "The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.":"The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.",
  "A customer with the same email address already exists in an associated website.": "A customer with the same email address already exists in an associated website.",
  "Invalid email": "Invalid email",
  "SUMMER COLLECTION {year}": "SUMMER COLLECTION {year}",
  "Colorful summer dresses are already in store": "Colorful summer dresses are already in store",
  "Learn more": "Learn more",
  "Dresses": "Dresses",
  "Cocktail & Party": "Cocktail & Party",
  "Linen Dresses": "Linen Dresses",
  "T-Shirts": "T-Shirts",
  "The office life": "The office life",
  "Find stunning women's cocktail dresses and party dresses. Stand out in lace and metallic cocktail dresses from all your favorite brands.": "Find stunning women's cocktail dresses and party dresses. Stand out in lace and metallic cocktail dresses from all your favorite brands.",
  "Shop now": "Shop now",
  "The Office Life": "The Office Life",
  "Summer Sandals": "Summer Sandals",
  "Eco Sandals": "Eco Sandals",
  "Subscribe to Newsletters": "Subscribe to Newsletters",
  "Be aware of upcoming sales and events. Receive gifts and special offers!": "Be aware of upcoming sales and events. Receive gifts and special offers",
  "Fashion to take away": "Fashion to take away",
  "Download our application to your mobile": "Download our application to your mobile",
  "Share Your Look": "Share Your Look",
  "My Account": "My Account",
  "My profile": "My profile",
  "Personal Details": "Personal Details",
  "Addresses details": "Addresses details",
  "My newsletter": "My newsletter",
  "Log out": "Log out",
  "My reviews": "My reviews",
  "Order history": "Order history",
  "Order details": "Order details",
  "My wishlist": "My wishlist",
  "Password change": "Password change",
  "Personal data": "Personal data",
  "Your e-mail": "Your e-mail",
  "Current Password": "Current Password",
  "You are not authorized, please log in.": "You are not authorized, please log in.",
  "Go To Product": "Go To Product",
  "Change to list view": "Change to list view",
  "Change to grid view": "Change to grid view",
  "Returns":"Returns",
  "My orders": "My orders",
  "Add the address": "Add the address",
  "Set as default shipping": "Set as default shipping",
  "Set as default billing": "Set as default billing",
  "House/Apartment number": "House/Apartment number",
  "Street Name": "Street Name",
  "City": "City",
  "State/Province": "State/Province",
  "Zip-code": "Zip-code",
  "Country": "Country",
  "Phone number": "Phone number",
  "Please select a country first":"Please select a country first",
  "This field is required": "Campo obbligatorio",
  "The field should have at least 2 characters": "The field should have at least 2 characters",
  "The field should have at least 4 characters": "The field should have at least 4 characters",
  "The field should have at least 8 characters": "The field should have at least 8 characters",
  "New Password": "New Password",
  "New Products": "New Products",
  "There are no shipping methods available for this country. We are sorry, please try with different country.": "There are no shipping methods available for this country. We are sorry, please try with different country.",
  "There was some error while trying to fetch shipping methods. We are sorry, please try with different shipping details.": "There was some error while trying to fetch shipping methods. We are sorry, please try with different shipping details.",
  "There was some error while trying to select this shipping method. We are sorry, please try with different shipping method.": "There was some error while trying to select this shipping method. We are sorry, please try with different shipping method.",
  "We can't find products matching the selection.":"We can't find products matching the selection.",
  'Page not found': 'Page not found',
  'Back to Home page': 'Back to Home page',
  'An error occurred': 'Si è verificato un errore, riprova più tardi per favore',
  "AllProductsFromCategory": "All {categoryName}",
  "Show more": "Show more",
  "Show less": "Show less",
  "Yes": "Yes",
  "No": "No",
  "Apply filters": "Apply filters",
  "The coupon code isn't valid. Verify the code and try again.": "The coupon code isn't valid. Verify the code and try again.",
  "From": "From",
  "To": "To",
  "Your customization": "Your customization",
  "Passwords don't match":"Passwords don't match",
  "The password must be at least 8 characters long and must contain at least: 1 uppercase or lowercase letter, 1 number, or one special character (E.g. , . _ & ? etc)":"The password must be at least 8 characters long and must contain at least: 1 uppercase or lowercase letter, 1 number, or one special character (E.g. , . _ & ? etc)",
  "Show all products":"Show all products",
  "Select previously saved address":"Select previously saved address",
  "Enter different address":"Enter different address",
  "You must confirm your account. Please check your email for the confirmation link.": "You must confirm your account. Please check your email for the confirmation link.",
  "Change Store":"Change Store",
  "Choose Currency":"Choose Currency",
  "Add a review":"Add a review",
  "Add to cart":"Add to cart",
  "Title":"Title",
  "Name":"Name",
  "Review":"Review",
  "Add review":"Add review",
  "Are you sure you would like to remove this item from the shopping cart?":"Are you sure you would like to remove this item from the shopping cart?",
  "Your cart is empty":"Your cart is empty",
  "Are you sure?":"Are you sure?",
  "{0} has been successfully removed from your cart":"{0} has been successfully removed from your cart",
  "Amount":"Amount",
  "Thank you for your order!":"Thank you for your order!",
  "Your Purchase":"Your Purchase",
  "Primary contacts for any questions":"Primary contacts for any questions",
  "Your Account":"Your account",
  "What can we improve":"What can we improve",
  "Payment date":"Payment date",
  "The user password was changed successfully updated!":"The user password was changed successfully updated!",
  "The user account data was successfully updated!":"The user account data was successfully updated!",
  "You submitted your review for moderation.": "You submitted your review for moderation.",
  "Starting at": "Starting at",
  "Other options":"Altre opzioni",
  "Introductory offer":"Offerta lancio",
  "Introductory offer text":"Risparmia subito sull’abbonamento (-25%) <br>in più in omaggio 6 mesi di abbonamento al Sole 24 Ore in digitale",
  "{durationQty} {duration} at {price} {discountedPrice}":"{durationQty} {duration} a <span style='font-family: system-ui; font-weight: bolder' class='text-dark/50 line-through'>{price}</span> {discountedPrice}",
  "{durationQty} {duration} at {discountedPrice}":"{durationQty} {duration} a {discountedPrice}",
  "then {price} {discountedPrice} / {durationQty} {duration}":" a seguire <span style='font-family: system-ui; font-weight: bolder' class='text-dark/50 line-through'>{price}</span> {discountedPrice} / {duration} | a seguire <span style='font-family: system-ui; font-weight: bolder' class='text-dark/50 line-through'>{price}</span> {discountedPrice} / {duration} | a seguire <span style='font-family: system-ui; font-weight: bolder' class='text-dark/50 line-through'>{price}</span> {discountedPrice} / ogni {durationQty} {duration}",
  "then {promotionalString} and then {priceString}":"a seguire {priceString}",
  "then {price} / {duration}":"a seguire {price} / {duration}",
  "or {price} / {duration}":"oppure {price} / {duration}",
  "or {price} {discounted} / {duration}":"oppure <span style='font-family: system-ui; font-weight: bolder' class='text-dark/50 line-through'>{price}</span> {discounted} / {duration}",
  "Subscribe":"Abbonati",
  "Choose":"Scegli",
  "Automatic renewal can be deactivated whenever you want":"Rinnovo automatico disattivabile quando vuoi",
  "Automatic renewal offer can be deactivated whenever you want":"Offerta a rinnovo automatico che puoi disattivare quando vuoi",
  "Automatically renewable offer, you can cancel the renewal every year within {days} days of expiration":"Offerta a rinnovo automatico, potrai annullare la ricorrenza ogni anno entro {days} giorni dalla scadenza",
  "addictional digital modules label":" | + 1 prodotto digitale a scelta | + {count} prodotti digitali a scelta",
  "Includes":"Comprende",
  "month":"mesi | mese | mesi",
  "quarter":"trimestri | trimestre | trimestri",
  "semester":"semestri | semestre | semestri",
  "year":"anni | anno | anni",
  "biennium":"bienni | biennio | bienni",

  "+ {price} / each {durationQty} {duration}":"  + {price} | + {price} / {duration} | + {price} ogni {durationQty} {duration}",
  "{price} / each {durationQty} {duration}":"  {price} | {price} / {duration} | {price} ogni {durationQty} {duration}",
  "{price} / trial {durationQty} {duration}":"  {price} | {price} il primo {duration} | {price} / {durationQty} {duration}",
  
  "{price} first {duration}(included {startup})":"{price} il primo {duration} (di cui {startup} per avviamento e formazione)",
  "{price}{discounted} first {duration}(included {startup})":"<span style='font-family: system-ui; font-weight: bolder' class='text-dark/50 line-through'>{price}</span>{discounted} il primo {duration} (di cui {startup} per avviamento e formazione)",

  "trial price {durationQty} {duration}":"Prezzo iniziale | Prezzo per il primo {duration} | {price} per i primi {durationQty} {duration}",
  "trial duration {trialDuration} {trialDurationSpan}":" per {trialDuration} {trialDurationSpan}",
  "regular duration {deliveryCost}":"A seguire {deliveryCost}",
  "promotional price {durationQty} {duration}":"Prezzo iniziale | Prezzo per il primo {duration} | {price} per i primi {durationQty} {duration}",
  
  "trial {price} {durationQty} {duration}":"Prezzo iniziale | Prezzo per il primo {duration} | {price} per i primi {durationQty} {duration}",
  "discounted {price} {discountedPrice} each {durationQty} {duration}":"<span style='font-family: system-ui; font-weight: bolder' class='text-dark/50 line-through'>{price}</span> {discountedPrice} | <span style='font-family: system-ui; font-weight: bolder' class='text-dark/50 line-through'>{price}</span> {discountedPrice} / {duration} | <span style='font-family: system-ui; font-weight: bolder' class='text-dark/50 line-through'>{price}</span> {discountedPrice} ogni {durationQty} {duration}",
  "discounted {price} {discountedPrice}":"<span style='font-family: system-ui; font-weight: bolder' class='text-dark/50 line-through'>{price}</span> {discountedPrice} | <span style='font-family: system-ui; font-weight: bolder' class='text-dark/50 line-through'>{price}</span> {discountedPrice} | <span style='font-family: system-ui; font-weight: bolder' class='text-dark/50 line-through'>{price}</span> {discountedPrice}",
  
  "{durationQty} {duration} of trial at {trialPrice}":"{durationQty} {duration} di prova GRATIS | {durationQty} {duration} di prova a {trialPrice} | {durationQty} {duration} di prova a {trialPrice}",
  "{durationQty} {duration} of trial at {trialPrice} then {price} {afterSpan}":"{durationQty} {duration} di prova GRATIS poi a {price} / {afterSpan} | {durationQty} {duration} di prova a {trialPrice} poi a {price} / {afterSpan} | {durationQty} {duration} di prova a {trialPrice} poi a {price} / {afterSpan}",
  "gift":"regalo",
  "landings add paper":"Aggiungi edizione cartacea",
  "landings paper required":"Edizione cartacea",
  "landings add paper subtitle":"Disponibile solo su abbonamento annuale",
  "choose plan items":" | Scegli 1 prodotto digitale a cui sei interessato | Scegli {qty} prodotti digitali a cui sei interessato",
  "choose payment frequency":"Scegli la frequenza di pagamento",
  "payment frequency":"frequenza di pagamento",
  "Payment frequency":"Frequenza di pagamento",
  "payment_frequency_M":"mensile",
  "payment_frequency_S":"semestrale",
  "payment_frequency_Q":"trimestrale",
  "payment_frequency_Y":"annuale",
  "payment_frequency_B":"biennale",
  "Choose product":"Scegli prodotto",
  "you choose":"Hai scelto",
  "best_solution":"La soluzione più scelta",
  "Contents available for all platforms":"Contenuti disponibili su PC, smartphone e app",
  "Il Sole 24 Ore digital version":"Il Sole 24 Ore in versione digitale",
  "{num} additional modules":" Nessun prodotto | 1 prodotto | {num} prodotti",
  "additional modules title":"Moduli digitali a scelta tra i prodotti dell’offerta editoriale premium e i quotidiani digitali per i professionisti",
  "promotional price":"Prezzo in promozione",
  "after price title":"Prezzo successivo al periodo promozionale",
  "compose your subscription banner text":"Puoi anche comporre l’abbonamento più adatto a te<br />selezionando singoli prodotti digitali",
  "View all available products":"Vedi tutti i prodotti disponibili",
  "shipping_kiosk":"Ritiro in edicola",
  "shipping_mail":"Consegna postale",
  "compose_your_subscription_title":"Seleziona tutti i prodotti a cui sei interessato",
  "V Added":"&check; Aggiunto",
  "Add":"Aggiungi",
  "Remove":"Rimuovi",
  "Recap":"Riepilogo",
  "Total":"Totale",
  "subscription_other_options_title":"Altre opzioni di abbonamento",
  "FAQ title":"Domande frequenti",
  "plans_title":"Configura il tuo piano ideale",
  "landing_default_title":"Affronta la realtà con la giusta informazione",
  "details":"dettagli",
  "buy":"acquista",
  "premium modules title":"Contenuti Editoriali Premium",
  "professional modules title":"Contenuti per i Professionisti",
  "subscription type":"tipologia di abbonamento",
  "choose your solution":"Scegli la soluzione più adatta alle tue esigenze",
  "product_add_to_cart":"Procedi con l'ordine",
  "Product page add paper":"Aggiungi versione cartacea",
  "delivery_2":"Consegna postale",
  "delivery_3":"Ritiro in edicola",
  "product_page_shipping_disclaimer":"La spedizione avverrà tramite Posta Ordinaria (si consiglia di verificare le condizioni applicate nella propria destinazione sul sito www.poste.it). Riceverai la prima copia del quotidiano indicativamente entro 1 settimana dalla data dell'ordine.",
  "product_page_shipping_disclaimer_edicola":" La consegna è soggetta ai periodi di chiusura e agli orari di servizio dell'edicola scelta. Il Servizio Abbonamenti ti invierà via e-mail la documentazione necessaria per il ritiro. La prima copia del quotidiano potrà essere ritirata indicativamente entro 2 settimane dalla data dell'ordine.",
  "Select {num} modules":"Seleziona {num} moduli a tua scelta | Seleziona 1 modulo a tua scelta | Seleziona {num} moduli a tua scelta",
  "You can login with:":"Puoi accedere anche con:",
  "Daily client service":"Servizio clienti Quotidiano",
  "Periodical client service":"Servizio clienti Periodici",
  "phone: {phone}" : "tel.: {phone}",
  "mail: {mail}": "email: <a href=\"mailto:{mail}\">{mail}</a>",
  "find_CL_01":"Sulle fatture dei nostri prodotti",
  "find_CL_02":"Sul buono di rinnovo ricevuto per posta",
  "find_CL_03":"Oppure sull'etichetta dove riportato l'indirizzo di spedizione",
  "You can pay with Scalapay":"Puoi pagare con Scalapay",
  "Renew your subscriptions":"Rinnova i tuoi abbonamenti",
  "Renew your subscriptions subtitle":"Per verificare lo stato dei tuoi abbonamenti e le offerte di rinnovo a te riservate, inserisci il <b>Codice cliente</b> e il <b>CAP</b> associato al tuo profilo",
  "Insert client code":"Inserisci codice cliente",
  "Insert CAP":"CAP",
  "Client code": "Codice Cliente",
  "Find client code": "Dove trovare il codice cliente",
  "CAP": "CAP",
  "Format validation error": "Il campo non rispetta i requisiti di formato",
  "{field} format not valid":"Formato {field} non valido",
  "CAP Tooltip":"Inserisci il CAP (codice di avviamento postale) che hai indicato quando hai sottoscritto il tuo abbonamento",
  "Other subscription options":"Tutte le opzioni di abbonamento",
  "404_title":"Oops! <b>Pagina non trovata</b>",
  "404_subtitle":"Spiacenti, non riusciamo a trovare la pagina che stai cercando",
  "Go to subscriptions":"Vai alle opzioni di abbonamento",
  "disclaimer and conditions of use.":"disclaimer e condizioni d'uso.",
  "confirm":"conferma",
  "company name":"ragione sociale",
  "exit":"esci",
  "expired subscriptions" : "abbonamenti scaduti",
  "active subscriptions" : "abbonamenti in scadenza",
  "no expired subscriptions" : "Non risultano abbonamenti scaduti associati al tuo codice cliente",
  "no active subscriptions" : "Non risultano abbonamenti in scadenza associati al tuo codice cliente",
  "expired on":"scaduto il",
  "expires on":"scade il",
  "month_0":"gennaio",
  "month_1":"febbraio",
  "month_2":"marzo",
  "month_3":"aprile",
  "month_4":"maggio",
  "month_5":"giugno",
  "month_6":"luglio",
  "month_7":"agosto",
  "month_8":"settembre",
  "month_9":"ottobre",
  "month_10":"novembre",
  "month_11":"dicembre",
  "subscription n {num}":"abbonamento n° {num}",
  "renew":"rinnova",
  "configure":"configura",
  "select":"seleziona",
  "WIP" : "Operazioni in corso, attendere, grazie",
  "Product added to cart":"Prodotto aggiunto al carrello",
  "txtUsername_field":"Username o email",
  "Hide": "Nascondi",
  "Show": "Mostra",
  "Remember me":"Ricordami",
  "starting from":"a partire da",
  "add paper S24H" : "Aggiungi Il Sole 24 Ore cartaceo",
  "card_subtitle_0":"Il Quotidiano in formato digitale su PC, smartphone e app",
  "card_subtitle_1":"Il Quotidiano digitale con 1 approfondimento a scelta",
  "card_subtitle_2":"Il Quotidiano digitale con 2 approfondimenti a scelta",
  "Adding to cart":" ... "
};
